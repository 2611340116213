import React, { useEffect } from "react";
import cloneDeep from "lodash/cloneDeep";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";

const tableHead = {
    projectName: "Project Name",
    created_at: "Date Transcribed",
    fileName: "File Name",
    username: "User Name",
    videoName: "Action"
};

const Table = ({transcriptions, setResponse}) => {

  const countPerPage = 10;
  const [value, setValue] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);

  const [collection, setCollection] = React.useState(
    cloneDeep(transcriptions?.slice(0, countPerPage))
  );


  const tableData = () => {
    return collection?.map((key, index) => tableRows({ key, index }));
  };

  const searchData = (val) => {
    const query = val.toLowerCase();

    setCurrentPage(1);
    const data = cloneDeep(
      collection.filter(item => item.projectName.toLowerCase().indexOf(query) > -1).slice(0, countPerPage)
    );

    setCollection(data);
  }

const updatePage = p => {
  setCurrentPage(p);
  const to = countPerPage * p;
  const from = to - countPerPage;
  setCollection(cloneDeep(transcriptions?.slice(from, to)));
};

  useEffect(() =>{

    setCollection(cloneDeep(transcriptions?.slice(0, countPerPage)))

    if (!value) {
        updatePage(1);
      } else {
        searchData(value);
      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, transcriptions])


  const tableRows = rowData => {
    const { key, index } = rowData;
    const tableCell = Object.keys(tableHead);
    const columnData = tableCell.map((keyD, i) => {
        if(keyD === "created_at"){
            var date = new Date(key[keyD])
            var stringDate = date.toString()
            return <td key={i} className="py-4 px-6">{stringDate}</td>;
        } else if (keyD === "projectName") {
            return <td key={i} className="py-4 px-6 font-bold">{key[keyD]}</td>;
        } else if (keyD === "videoName") {
            return <td key={i} className="py-4 px-6 font-bold text-yellow-400 cursor-pointer hover:text-yellow-100 " onClick={() => onTableClick(key)}>View</td>;
        } 
        else{
            return <td key={i} className="py-4 px-6">{key[keyD]}</td>;

        }

    });

    return <tr key={index} className="bg-gray-800 border-blue-400 border-b dark:bg-gray-900 dark:border-gray-700">{columnData}</tr>;
  };

  const headRow = () => {
    return Object.values(tableHead).map((title, index) => (
      <td key={index} className="py-3 px-6">{title}</td>
    ));
  };

  const onTableClick = (data) =>{
    setResponse(data)
  }

if(transcriptions !== undefined){
  return (
    <div className='bg-gray-800 rounded-lg p-8 border-[1px] border-blue-900'>
      <div className="search">
        <input
          placeholder="Search By Project Name"
          value={value}
          onChange={e => setValue(e.target.value)}
          className="px-4 py-1 rounded-lg w-96 border-2 border-blue-400 mb-5 text-gray-200 bg-gray-900"
        />
      </div>
      <div className="overflow-x-auto relative rounded-[3px]">
      <table className="w-full text-sm border-[1px] border-blue-400 text-left text-gray-200 dark:text-gray-400">
        <thead className="text-xs text-gray-200 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr className="bg-gray-900 border-b border-blue-400 dark:bg-gray-800 dark:border-gray-700">
                {headRow()}
            </tr>
        </thead>
        <tbody className="">{tableData()}</tbody>
      </table>
      </div>
      <div className="mt-4">
        <Pagination
            pageSize={countPerPage}
            onChange={updatePage}
            current={currentPage}
            total={transcriptions?.length}
        />
      </div>
      
    </div>
  );
}else {
    return (
        <div>

        </div>
    )
}

};
export default Table;
