import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {KeyIcon, NewspaperIcon, VideoCameraIcon} from '@heroicons/react/24/solid'

function ProjectDetails({response, projectName, freshProject, fileData}) {

    var keyPoint = "1." + response?.keyPoints

    var nameProject = projectName !== "" ? projectName : response.projectName
    var fileName = fileData?.name !== undefined || "" ? fileData.name : response.fileName
    var summary = response.summarization !== undefined || "" ? response.summarization.trim() : response.summary
    var keyPoints = response?.keyPoints?.startsWith("1.") ? response.keyPoints : keyPoint


    const notify = () => toast('🦄 Copied!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });

    const copyText = (text) => {
        navigator.clipboard.writeText(text)
        notify()

    }

    return (
        <div>
            <div className='text-gray-200 mr-10 ml-5 mt-10 mb-10 grid grid-cols-2 gap-4'>
                <div className='bg-gray-900 p-4 rounded-lg border-blue-800 border-2 shadow-sm shadow-blue-800 col-span-2 mb-10'>
                    <div className='grid grid-cols-2'>
                        <div>
                            <div className='text-2xl font-medium text-gray-100'>
                            {nameProject}
                            </div>
                            <div className='text-xs mt-3 text-gray-100'>
                            <b>MP3 File Name:</b> {fileName}
                            </div>
                        </div>
                        <div>
                            <button 
                                className='bg-blue-800 ml-auto flex px-8 rounded-lg border-2 border-gray-500 shadow-md hover:bg-blue-700 font-medium text-white py-1 flex mt-5 mr-5'
                                onClick={freshProject}
                            >
                               <NewspaperIcon className="h-5 w-5 mt-[1.5px] text-gray-300 mr-2"/> New Transkription
                            </button>
                        </div>

                    </div>
                </div>
                <div className='bg-gray-900 p-8 rounded-lg border-blue-800 border-2 shadow-sm shadow-blue-800'>
                    <div className='mb-5 text-2xl font-medium text-gray-300 underline flex '>
                    <VideoCameraIcon className="h-6 w-6 mt-1 text-gray-300 mr-3"/>Video Summary:
                    </div>
                    <div className='bg-[#03002e] text-white p-6 text-[19px] rounded-lg leading-relaxed border-2 border-blue-800 hover:shadow-lg hover:shadow-gray-500 cursor-pointer whitespace-pre-line' onClick={() => copyText(summary)}>
                        {summary}
                    </div>
                </div>
                <div className='bg-slate-900 p-8 rounded-lg border-blue-800 border-2 shadow-sm shadow-blue-800 whitespace-pre-line'>
                    <div className='mb-5 text-2xl font-medium text-gray-300 underline flex'>
                        <KeyIcon className="h-6 w-6 mt-1 text-gray-300 mr-3"/>
                        Key Takeaways
                    </div>
                    <div className='bg-[#03002e] text-white text-[19px] p-6 rounded-lg leading-relaxed border-2 border-blue-800 hover:shadow-lg hover:shadow-gray-500 cursor-pointer' onClick={() => copyText(keyPoints)}>
                        {keyPoints}
                    </div>
                </div>
                <div className=' mt-10 bg-gray-900 p-8 rounded-lg border-blue-800 border-2 shadow-sm shadow-blue-800 whitespace-pre-line col-span-2'>
                    <div className='mb-5 text-2xl font-medium text-gray-300 underline'>
                        Video Transcription
                    </div>
                    <div className='bg-[#03002e] p-6 rounded-lg leading-relaxed border-2 border-blue-800 hover:shadow-lg hover:shadow-gray-500 cursor-pointer' onClick={() => copyText(response?.translation)}>
                    "{response?.translation}"
                    </div>
                </div>
                <ToastContainer />
            </div>
        </div>
    );
}

export default ProjectDetails;