import axios from 'axios'
import { useState, useEffect } from 'react'
import { supabase } from '../../../supabaseClient'

const Account = ({ session, subInfo }) => {
  const [message, setMessage] = useState()
  const [subStripe, setSubStripe] = useState()

  const cancelSubscription  = process.env.REACT_APP_API_ENDPOINT_URL + 'cancelSub'

  useEffect(() =>{

    const seeSubInfo  = process.env.REACT_APP_API_ENDPOINT_URL + 'seeSubInfo'


    if(subInfo !== undefined && subInfo?.length !== 0){
    axios.post(seeSubInfo, {sessionID: subInfo[0].sessionID})
    .then((res) =>{
      setSubStripe(res.data.sub)
    })
  }

  }, [subInfo, setSubStripe])

  const cancelSub = (data) =>{
    setMessage("Cancelling Subscription")
    axios.post(cancelSubscription, {subID: data}).then(async (res) =>{
      setMessage("Subscription Cancelled")
      await supabase.from('Payments')
      .update({
          tokens: 0,
      })
      .match({
          email: session.user.email
      })
      window.location.reload()
    })

  }

  return (
    <div aria-live="polite" className='bg-gray-900 mr-10 mt-[200px] rounded-lg p-10 text-gray-200 border-2 border-blue-800 shadow-md shadow-blue-900'>
      
      <div>Email: {session.user.email}</div>
      {subInfo === undefined || subStripe?.status === "canceled" || subInfo?.length === 0 || subStripe === undefined ? <></> :
      <div>
        <div className='capitalize'>Subscription Status: {subStripe?.status}</div>
        <div className='mt-3 text-red-600 font-medium'>
          <b>Note:</b> Canceling Subscription Means Access to Transkription.io is denied and all credits are erased. Transkription.io is only available to subscribed users.
        </div>
        <button
          type="button"
          className="mt-6 bg-red-800 border-2 border-red-900 px-4 py-1 hover:bg-red-900 rounded-md"
          onClick={() => cancelSub(subStripe.id)}
        >
          Cancel Subscription
        </button>
      </div>
      }
      <button
        type="button"
        className="mt-6 bg-blue-800 border-2 border-blue-900 px-4 py-1 hover:bg-blue-900 rounded-md"
        onClick={() => supabase.auth.signOut()}
      >
        Sign Out
      </button>
      <div className='mt-4'>
        {message}
      </div>
    </div>
  )
}

export default Account