import React from 'react';
import axios from 'axios';
import { supabase } from '../../../supabaseClient';
import ProjectDetails from './TranslatorComponent/ProjectDetails';
import Submition from './TranslatorComponent/Submition';
import RecentTranscriptions from './TranslatorComponent/RecentTranscriptions';
import { useNavigate } from 'react-router-dom';
import SubRequest from './TranslatorComponent/SubRequest';


function Translator({
    session, 
    subscribed,
    fileData,
    setFileData,
    loading,
    setLoading,
    response,
    setResponse,
    projectName,
    setProjectName,
    error,
    setError,
    freshProject,
    setTokens
}) {
    const navigate = useNavigate();

    const generateTranskription  = process.env.REACT_APP_API_ENDPOINT_URL + 'generate'


    const callWhisper = async (info) => {

        var email = session.user.email
        var tokens = 0

        const { data } = await supabase
        .from('Translations')
        .select('username, projectName')
        .eq("username", email).eq("projectName", projectName)

         await supabase
        .from('Payments')
        .select('*')
        .eq("email", email).then((res) => {
            tokens = res.data[0].tokens
        })

        setError('')

        if(data.length > 0){
            setError("Project name already exists, please write another name.")
        } else if(tokens === 0){
            setError("Insufficient Credits")
        } else{

            const ThisFunction = async() =>{
                
                const { data } = await supabase
                .from('Translations')
                .select('username, projectName')
                .eq("username", email).eq("projectName", projectName)

                if(data.length === 0){
                    var intervalId= window.setInterval(async function(){
                        // call your function here
                        const { data } = await supabase
                        .from('Translations')
                        .select('*')
                        .eq("username", email).eq("projectName", projectName)

                        console.log("Polling")
                        if(data.length > 0){
                            setResponse(data[0])
                            setLoading(false)
                            clearInterval(intervalId) 

                            await supabase
                            .from('Payments')
                            .select('*')
                            .eq("email", email)
                            .then((res) =>{
                                setTokens(res?.data[0]?.tokens)
                            })
                        }
                      }, 5000);
                }
            }

        ThisFunction()    

        setResponse()
        setLoading(true)

        const beInfo = {
            base64: info,
            userEmail: email,
            fileName: fileData.name,
            projName: projectName
        }



        axios.post(generateTranskription, beInfo)
        }
    }


    return (
        <div>
            {subscribed ?
            (response === undefined ?
            <div>
            <Submition 
            callWhisper={callWhisper}
            setProjectName={setProjectName}
            setFileData={setFileData}
            projectName={projectName}
            fileData={fileData}
            error={error}
            loading={loading}
            session={session}
            />
            <RecentTranscriptions 
            setResponse={setResponse}
            session={session}
            />
            </div>
            :
            <ProjectDetails 
            response={response}
            freshProject={freshProject}
            projectName={projectName}
            fileData={fileData}
            />
            )
            : 
            <SubRequest navigate={navigate}/>
            }
        </div>
    );
}

export default Translator;