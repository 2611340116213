import React from 'react';
import FileBase64 from 'react-file-base64';
import loadingGif from '../../../../loading.gif'

function Submition({
    setProjectName,
    setFileData,
    projectName,
    fileData,
    callWhisper,
    error,
    loading,
}) {

    const onY2MateClick = () =>{
        window.open("https://en.y2mate.is/70/")
    }

    return (
        <div className='bg-gray-900 p-5 mt-20 m-auto flex mb-10 mr-10 ml-4 rounded-lg text-white grid gap-y-10 border-2 border-blue-800 shadow-lg shadow-gray-900'>
            <div className='text-2xl font-bold'>
            Upload MP3 Below
            </div>
            <div className='-mt-10 text-sm text-gray-300'>
                MP3 files must be below 3MB. Give your transkription a project name too.
                <div className='mt-2 mb-5'>
                Want to convert a YouTube video to MP3? <span className='text-blue-400 underline cursor-pointer' onClick={onY2MateClick}>Go to this link</span> and convert your video to an MP3 that's under 3MB.
                </div>
            </div>
            <div className='-mt-10'>
                <div className="text-lg mb-2">
                    Project Name
                </div>
                <input 
                onChange={(e) => setProjectName(e.target.value)}
                placeholder="Input Unique Project Name"
                className="py-1 px-2 rounded-lg -mb-14 w-[400px] text-gray-200 border-2 border-blue-800 bg-gray-900 shadow-sm shadow-blue-900"
                />
            </div>
            <FileBase64 
            type="file" 
            multiple={false} 
            onDone={(e) => setFileData(e)}
            className=""
            />
            <div>
                {/* If file data is not there disable the button */}
                {projectName.length > 0 && fileData !== undefined ?
                <div>
                    <button disabled={fileData?.file?.size > 3100000 ? true : false} onClick={() => callWhisper(fileData.base64)} className="disabled:opacity-75 bg-blue-800 py-1 px-4 rounded-lg border-2 hover:bg-blue-500 border-gray-200 active:bg-blue-500">
                    Transcribe and Summarize!
                    </button>
                    <div className='mt-4 text-red-400'>
                        {error}
                    </div>
                </div>
                : <></>}
                {fileData?.file?.size > 3100000 ?
                <div className='text-red-400 mt-4'>
                    File Size Too Big, Upload File Less Than 3MB
                </div>
                :
                <></>
                }
                {loading ? 
                <div className='' style={{textAlign: '-webkit-center'}}>
                <div>
                    <div className='text-2xl mb-2 font-bold'>Your Video Transcription is loading up!</div>It can take up to a minute for larger videos so in the meantime we hope you enjoy Carlton dancing!
                </div>
                <img src={loadingGif} className="mt-5" alt="loading" /> 
                </div>
                : <></>}
            </div>
        </div>
    );
}

export default Submition;