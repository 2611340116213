import { useEffect, useState } from 'react';
import Auth from './components/auth/Auth';
import Dashboard from './components/dashboard/Dashboard';
import { supabase } from './supabaseClient';

function App() {

  const [session, setSession] = useState(null)

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })
  }, [])

  return (
    <div className="bg-gray-800">
      <header className="">
      {!session ? (
        <Auth />
      ) : (
        <Dashboard key={session.user.id} session={session} />
      )}
      </header>
    </div>
  );
}

export default App;
