import React, { useEffect, useState } from 'react';
import { supabase } from '../../../../supabaseClient';
import Table from './RecentTranscriptonsComponent/Table';

function RecentTranscriptions({setResponse, session}) {

    const [transcriptions, setTranscriptions] = useState()

    useEffect(() =>{

        var email = session.user.email

        async function fetchData() {
            // You can await here
            const { data } = await supabase
            .from('Translations')
            .select('*')
            .eq("username", email)

            setTranscriptions(data)
          }
          fetchData();
    }, [setTranscriptions, session])

    return (
        <div className='bg-gray-900 p-5 mt-20 m-auto flex mb-10 mr-10 ml-4 rounded-lg text-white grid gap-y-10 border-2 border-blue-800 shadow-lg shadow-gray-900'>
            <div className='text-2xl font-bold'>
                Recent Transkriptions
            </div>
            <div>
                <Table transcriptions={transcriptions} setResponse={setResponse} />
            </div>
        </div>
    );
}

export default RecentTranscriptions;