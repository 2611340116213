import axios from 'axios';
import React, { useState } from 'react';
import { supabase } from '../../../supabaseClient';

function Pricing({session, subscribed}) {

    const [stripeLoading, setStripeLoading] = useState()

    const pricingInfo = [
        {info: "100 Transcriptions of MP3s each month"},
        {info: "Summary overview of transcription generated by Transkription.io"},
        {info: "Full verbal transcription generated by Transkription.io"},
        {info: "3 Key Points of MP3 available to share generated by Transkription.io"},
        {info: "History of all your transcriptions is searchable and available to you"},
    ]

    const chargeExisting  = process.env.REACT_APP_API_ENDPOINT_URL + 'chargeExistingPro'
    const chargeNew  = process.env.REACT_APP_API_ENDPOINT_URL + 'chargeNewPro'


    const stripeChargePro = async() =>{

        setStripeLoading("Taking you to Stripe page to finish transaction")

        const { data } = await supabase
        .from('Payments')
        .select('*')
        .eq("email", session.user.email)

        if(data.length > 0){
            const custID = data[0].customerID
            axios.post(chargeExisting, {custID: custID})
            .then(async (res) =>{

                await supabase.from('Payments')
                .update({
                    sessionID: res.data.session.id,
                    paymentPlan: res.data.pricingPlan
                })
                .match({
                    customerID: custID
                })
                .then(() => {
                        setStripeLoading()

                        window.open(res.data.session.url)
                })
            })

        } else {
            axios.post(chargeNew, {email: session.user.email})
            .then(async (res) =>{

                await supabase.from('Payments').insert([
                    {
                        email: session.user.email,
                        customerID: res.data.customerID,
                        tokens: 0,
                        paymentPlan: res.data.pricingPlan,
                        sessionID: res.data.session.id,
                        tokensAdded: false
                    }]).then(() => {
                        setStripeLoading()

                        window.open(res.data.session.url)
                    })
            })
        }
    }

    return (
        <div className='mt-[120px] mx-10 bg-gray-900 border-2 text-xl font-medium border-blue-800 p-5 text-gray-200 rounded-lg shadow-md shadow-blue-900 flex justify-center text-center'>
            <div className='bg-gray-800 mx-40 py-5 rounded-lg border-2 border-blue-900 shadow-sm shadow-blue-900'>
                <div className='text-2xl'>
                    Transkription Pro Plan
                </div>
                <div className='text-6xl text-center flex justify-center mt-5'>
                    <div>
                    $50
                    </div>
                    <div className='text-lg flex flex-col-reverse'>
                        /month
                    </div>
                </div>
                <div className='text-xl mt-5'>
                    100 Credit Package
                </div>
                <div className='text-base mt-2 bg-gray-900 py-4 px-5 text-left'>
                    <ul className='list-disc px-5'>
                        {pricingInfo.map((price,index) => {
                            return(
                                <li className='mt-2' key={index}>
                                    {price.info}
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div>
                    {subscribed ?
                    <div className='mt-10 mx-5 bg-gray-900 p-2 rounded-lg'>
                        Currently: Subscribed to Pro Plan
                    </div>
                    :
                    <button className='mt-5 border-2 px-5 py-1 rounded-lg bg-blue-800 border-2 hover:bg-blue-700' onClick={() => stripeChargePro()}>
                        Purchase
                    </button>
                    }
                </div>
                <div className='mt-5'>
                        {stripeLoading}
                </div>
            </div>
        </div>
    );
}

export default Pricing;