import React from 'react';

function SubRequest({navigate}) {
    return (
        <div>
            <div className='mt-[120px] mx-10 bg-gray-900 border-2 text-xl font-medium border-blue-800 p-5 text-gray-200 rounded-lg shadow-md shadow-blue-900'>
                <div>Subscribe to our Transkription pro plan to get started!</div>
                <button className='mt-5 mb-2 px-4 py-1 bg-blue-800 rounded-md border-2 border-blue-900 hover:bg-blue-500' onClick={() => navigate('/pricing')}>
                    Go to Pricing Page
                </button>
            </div>
        </div>
    );
}

export default SubRequest;