import React from 'react';
import { saveAs } from 'file-saver'
import { useState } from 'react';
import axios from 'axios';

function ImageGenerator(props) {

    const [image, setImage] = useState()

  const ImgGen  = process.env.REACT_APP_API_ENDPOINT_URL + 'image'


  const callSD = (data) => {
    axios.post(ImgGen).then((res) => 
    setImage(res.data.data)
    )
  }

  const downloadImage = () => {
    saveAs(`data:image/jpeg;base64,${image}`, 'image.jpg') // Put your image url here.
  }   

    return (
        <div>
            <div>
                <button onClick={() => callSD()}>
                Click for image on the backend
                </button>
            <div>
                <img src={`data:image/jpeg;base64,${image}`} alt="sd"/>
            </div>
            </div>
            <div>
                <button onClick={downloadImage} className="bg-purple-500">Download!</button>
            </div>
        </div>
    );
}

export default ImageGenerator;