import { useState } from 'react'
import { supabase } from '../../supabaseClient'
import logo from '../../fresh.png'

export default function Auth() {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')

  const handleLogin = async (e) => {
    e.preventDefault()

    try {
      setLoading(true)
      const { error } = await supabase.auth.signInWithOtp({ email })
      if (error) throw error
      alert('Check your email for the login link!')
    } catch (error) {
      alert(error.error_description || error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="flex h-screen">
      <div className="m-auto form-widget bg-gray-900 text-gray-200 px-20 pb-14 pt-10 rounded-lg border-2 border-blue-800 shadow-lg shadow-blue-900" aria-live="polite">
        <h1 className="header"><img src={logo} alt="logo"/></h1>
        <p className="mt-10">
          Sign in via magic link with your email below
        </p>
        {loading ? (
          'Sending magic link...'
        ) : (
          <div>
            <label htmlFor="email">Email</label>
            <input
              id="email"
              className="ml-2 px-2 py-[2px] w-72 rounded-md mt-3 bg-gray-500"
              type="email"
              placeholder="Your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div>
            <button className="mt-6 bg-blue-800 px-4 py-1 rounded-lg border-2 border-blue-900 hover:bg-blue-900" aria-live="polite" onClick={handleLogin}>
              Send magic link
            </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}