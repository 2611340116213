import React, { useEffect, useState } from 'react';
import { Link, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import ImageGenerator from './dash_components/ImageGenerator';
import Translator from './dash_components/Translator';
import logo from '../../fresh.png'
import { CreditCardIcon, GlobeAmericasIcon, HomeIcon, UserIcon } from '@heroicons/react/24/solid';
import { supabase } from '../../supabaseClient';
import axios from 'axios';
import Pricing from './dash_components/Pricing';
import Account from './dash_components/Account';

function Dashboard({ session}) {

const [tokens, setTokens] = useState()
const [subscribed, setSubscribed] = useState(false)

const [fileData, setFileData] = useState()
const [loading, setLoading] = useState(false)
const [response, setResponse] = useState()
const [projectName, setProjectName] = useState("")
const [error, setError] = useState("")
const [subInfo, setSubInfo] = useState()

useEffect(() =>{

    const seeSubInfo = process.env.REACT_APP_API_ENDPOINT_URL + 'seeSubInfo'

    const asyncFunction = async() =>{
        const { data } = await supabase
        .from('Payments')
        .select('*')
        .eq("email", session.user.email)

        setSubInfo(data)

        if(data.length > 0){
            const sessionID = data[0].sessionID
            axios.post(seeSubInfo, {sessionID: sessionID})
            .then(async (res) =>{
                const status = res.data.sub.status

                const dateStarted = res.data.sub.current_period_start.toString()
                const sbDateStarted = data[0].dateStarted
                const tokens = data[0].tokens
                if((dateStarted !== sbDateStarted) && status === "active"){
                    const newTokens = tokens + 100
                    await supabase.from('Payments')
                    .update({
                        tokens: newTokens,
                        dateStarted: dateStarted,
                        tokensAdded: true
                    })
                    .match({
                        email: session.user.email
                    })
                    setSubscribed(true)

                } else if(status === "active"){
                    setSubscribed(true)
                } else(
                    setSubscribed(false)
                )
            }).then(async() =>{
                const { data } = await supabase
                .from('Payments')
                .select('*')
                .eq("email", session.user.email)

                const SBTokens = data[0].tokens

                setTokens(SBTokens)
            })
        } else {
            setSubscribed(false)
        }
        

    }

    asyncFunction()
}, [setTokens, session, setSubInfo])

  const router = useLocation()

  const freshProject = () => {
    setProjectName('')
    setFileData()
    setResponse()
    setLoading(false)
    setError("")
}

    return (
        <div className='flex grid grid-cols-4 gap-4 bg-gray-800'>
            <div className='col-span-1 border-gray-40'>
                <div className='text-gray-400 text-[42px] font-medium bg-[#03002e] border-b-[2px] border-r-[2px] border-blue-800 rounded-br-3xl pb-10 pt-6 shadow-sm shadow-blue-800 pl-5'>
                    <img src={logo} alt="logo"/>
                </div>
                <div className='pl-4 py-10 text-white text-2xl rounded-tr-3xl bg-[#03002e] shadow-xl shadow-blue-800 border-t-[2px] border-r-[2px] border-blue-800 mt-10 h-screen'>
                    <Link to='/dashboard' >
                        <div onClick={freshProject} className={`hover:text-blue-800 flex pt-4 cursor-pointer ${router.pathname === "/dashboard" ? 'text-blue-600 font-bold underline underline-offset-4' : 'text-gray-400'}`}>
                            <HomeIcon className='h-6 w-6 mt-1 mr-2'/> Dashboard
                        </div>
                    </Link>
                    <Link to='/pricing' >
                        <div className={`flex hover:text-blue-800 pt-4 cursor-pointer ${router.pathname === "/pricing" ? 'text-blue-600 font-bold underline underline-offset-4' : 'text-gray-400'}`}>
                            <CreditCardIcon className='h-6 w-6 mt-1 mr-2'/> Pricing
                        </div>
                    </Link>
                    <Link to='/account' >
                        <div className={`flex hover:text-blue-800 pt-4 cursor-pointer ${router.pathname === "/account" ? 'text-blue-600 font-bold underline underline-offset-4' : 'text-gray-400'}`}>
                            <UserIcon className='h-6 w-6 mt-1 mr-2'/> Account
                        </div>
                    </Link>
                    <div>
                        <div className='flex border-2 shadow-md shadow-blue-900 border-blue-800 mr-5 mt-10 p-2 rounded-lg bg-slate-900'>
                        <GlobeAmericasIcon className='h-6 w-6 mt-1 mr-2'/>Credits: {tokens}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-span-3"> 
                <Routes>
                    <Route path="/success" element={<Navigate to ="/dashboard"/>} />
                    <Route path="/login" element={<Navigate to ="/dashboard"/>} />
                    <Route path="*" element={<Navigate to ="/dashboard"/>} />
                    <Route path="/#" element={<Navigate to ="/dashboard"/>} />
                    <Route path="/" exact element={
                        <Translator 
                        session={session} 
                        subscribed={subscribed}
                        fileData={fileData}
                        setFileData={setFileData}
                        loading={loading}
                        setLoading={setLoading}
                        response={response}
                        setResponse={setResponse}
                        projectName={projectName}
                        setProjectName={setProjectName}
                        error={error}
                        setError={setError}
                        freshProject={freshProject}
                        setTokens={setTokens}
                        />
                    } 
                    />
                    <Route path="/dashboard" exact element={
                        <Translator 
                        session={session} 
                        subscribed={subscribed}
                        fileData={fileData}
                        setFileData={setFileData}
                        loading={loading}
                        setLoading={setLoading}
                        response={response}
                        setResponse={setResponse}
                        projectName={projectName}
                        setProjectName={setProjectName}
                        error={error}
                        setError={setError}
                        freshProject={freshProject}
                        setTokens={setTokens}
                        />
                    } />
                    <Route path="/imagegenerator" exact element={<ImageGenerator />} />
                    <Route path="/pricing" exact element={<Pricing session={session} subscribed={subscribed}/>} />
                    <Route path="/account" exact element={<Account session={session} subscribed={subscribed} subInfo={subInfo} />} />
                </Routes>
            </div>
        </div>
    );
}

export default Dashboard;